import { MapPin, MailOpen, PhoneCall } from "lucide-react";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    // <!-- Footer Section Start -->
    <div
      className="section footer-section footer-section-03"
      style={{ backgroundImage: "url(/img/bg/footer-bg.jpg)" }}
    >
      <div className="container">
        {/* <!-- Footer Widget Wrap Start --> */}
        <div className="footer-widget-wrap">
          <div className="row">
            <div className="col-6 col-lg-3 col-sm-6">
              {/* <!-- Footer Widget Start --> */}
              <div className="footer-widget">
                <h4 className="footer-widget-title">Company</h4>
                <div className="widget-link">
                  <ul className="link">
                    <li>
                      <NavLink to="/aboutUs">About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to="/seven83_blog">News and Updates</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Terms & Conditions</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Policy</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 col-sm-6">
              {/* <!-- Footer Widget Start --> */}
              <div className="footer-widget">
                <h4 className="footer-widget-title">Services</h4>
                <div className="widget-link">
                  <ul className="link">
                    <li>
                      <NavLink to="/serviceNow">ServiceNow</NavLink>
                    </li>
                    <li>
                      <NavLink to="/dataAI">Data & AI </NavLink>
                    </li>
                    <li>
                      <NavLink to="/cloud">Cloud</NavLink>
                    </li>
                    <li>
                      <NavLink to="/consulting">Consulting</NavLink>
                    </li>
                    <li>
                      <NavLink to="/enterpriseIntegration">
                        Enterprise Integration
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/modernizeTransform">
                        Modernize & Transform
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/microsoftDynamics">
                        Microsoft Dynamics 365
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/appDevelopment">App Development</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- Footer Widget End --> */}
            </div>
            <div className="col-6 col-lg-3 col-sm-6">
              {/* <!-- Footer Widget Start --> */}
              <div className="footer-widget">
                <h4 className="footer-widget-title">Industries</h4>
                <div className="widget-link">
                  <ul className="link">
                    <li>
                      <NavLink to="/government">Government</NavLink>
                    </li>
                    <li>
                      <NavLink to="/healthcare">Healthcare</NavLink>
                    </li>
                    <li>
                      <NavLink to="/logistics">Logistics</NavLink>
                    </li>
                    <li>
                      <NavLink to="/manufacturing">Manufacturing</NavLink>
                    </li>
                    <li>
                      <NavLink to="/telecommunications">
                        Telecommunications
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/bankingFinancialInsurance">
                        Banking, Financial & Insurance
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- Footer Widget End --> */}
            </div>
            <div className="col-6 col-lg-3 col-sm-6">
              {/* <!-- Footer Widget Start --> */}
              <div className="footer-widget">
                <h4 className="footer-widget-title">Contact Information</h4>
                <div className="widget-info">
                  <ul>
                    <li>
                      <div className="info-icon">
                        <PhoneCall size="20" />
                      </div>
                      <div className="info-text">
                        <span>
                          <NavLink to="#">+91 9154 783 077</NavLink>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="info-icon">
                        <MailOpen size="20" />
                      </div>
                      <div className="info-text">
                        <span>
                          <NavLink to="mailto:info@783systems.com">
                            info@783systems.com
                          </NavLink>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="info-icon">
                        <MapPin size="20" />
                      </div>
                      <div className="info-text">
                        <span>
                          Plot No 14/2, Dwaraka Park View, 1st Floor, Sector-1,
                          HUDA Techno Enclave, Madhapur, Hyderabad, Telangana
                          500081.
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- Footer Widget End --> */}
            </div>
          </div>
        </div>
        {/* <!-- Footer Widget Wrap End --> */}
      </div>

      {/* <!-- Footer Copyright Start --> */}
      <div className="footer-copyright-area">
        <div className="container-fluid px-lg-5">
          <div className="footer-copyright-wrap mx-lg-5">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-4">
                <div className="footer-social">
                  <ul className="social">
                    <li>
                      <NavLink
                        className="btn"
                        to="https://www.facebook.com/people/Seven83-Systems/61562783654672/"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          id="Layer_1"
                          viewBox="0 0 310 310"
                        >
                          <g id="XMLID_834_">
                            <path
                              id="XMLID_835_"
                              d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064   c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996   V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545   C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703   c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"
                            />
                          </g>
                        </svg>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="btn"
                        to="https://x.com/seven83systems"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          shapeRendering="geometricPrecision"
                          textRendering="geometricPrecision"
                          imageRendering="optimizeQuality"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          viewBox="0 0 512 462.799"
                        >
                          <path
                            fillRule="nonzero"
                            d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"
                          />
                        </svg>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        target="_blank"
                        className="btn"
                        to="https://www.linkedin.com/company/seven83-systems/"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <g>
                            <path d="M116.504,500.219V170.654H6.975v329.564H116.504   L116.504,500.219z M61.751,125.674c38.183,0,61.968-25.328,61.968-56.953c-0.722-32.328-23.785-56.941-61.252-56.941   C24.994,11.781,0.5,36.394,0.5,68.722c0,31.625,23.772,56.953,60.53,56.953H61.751L61.751,125.674z M177.124,500.219   c0,0,1.437-298.643,0-329.564H286.67v47.794h-0.727c14.404-22.49,40.354-55.533,99.44-55.533   c72.085,0,126.116,47.103,126.116,148.333v188.971H401.971V323.912c0-44.301-15.848-74.531-55.497-74.531   c-30.254,0-48.284,20.38-56.202,40.08c-2.897,7.012-3.602,16.861-3.602,26.711v184.047H177.124L177.124,500.219z"></path>
                          </g>
                        </svg>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                {/* <!-- Footer Copyright Text Start --> */}
                <div className="copyright-text text-end">
                  <p>
                    © Copyrights 2024 Seven83 Systems Pvt.Ltd. All rights
                    reserved.
                  </p>
                </div>
                {/* <!-- Footer Copyright Text End --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer Copyright End --> */}
    </div>
    // <!-- Footer Section End -->
  );
}

export default Footer;
