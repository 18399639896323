import HeroSection from "../components/HomeHeroSection";
import HomeWhyChooseSection from "../components/HomeWhyChooseSection";
import HomeIndustriesSection from "../components/HomeIndustriesSection";
import { Play, MoveRight } from "lucide-react";
import HomeNewsUpdatesSection from "../components/HomeNewsUpdatesSection";
import { NavLink } from "react-router-dom";
function Home() {
  return (
    <>
      <HeroSection />
      {/* <!-- whychoose --> */}
      <HomeWhyChooseSection />
      {/* <!-- Our Services --> */}
      <div
        className="section seven-solution-section section-padding"
        style={{ backgroundImage: "url(img/bg/choose-us-bg.jpg)" }}
      >
        <div className="container">
          {/* <!-- Solution Wrap Start --> */}
          <div className="solution-wrap">
            <div className="section-title text-center">
              {/* <!-- <h3 className="sub-title">Services</h3> --> */}
              <h2 className="title">Our Services</h2>
            </div>
            {/* <!-- Solution Content Wrap Start --> */}
            <div className="solution-content-wrap">
              <div className="row">
                <div className="col-lg-7">
                  {/* <!-- Solution Item Start --> */}
                  <div
                    className="solution-item solution-item-big"
                    data-aos="flip-left"
                  >
                    <div className="solution-img">
                      <a href="#">
                        <img src="img/ServiceNow.jpg" alt="" />
                      </a>
                    </div>
                    <div className="solution-content">
                      <div className="solution-title">
                        <h4 className="sub-title">ServiceNow</h4>
                        <h3 className="title">
                          <a href="#">
                            Innovative AI Solutions on the ServiceNow Platform
                          </a>
                        </h3>
                      </div>
                      <a className="popup-video" href="#">
                        <img src="img/icons/Sustain-icon.svg" />
                      </a>
                    </div>
                  </div>
                  {/* <!-- Solution Item End --> */}
                </div>
                <div className="col-lg-5">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <!-- Solution Item Start --> */}
                      <div
                        className="solution-item solution-item-sm"
                        data-aos="flip-left"
                      >
                        <div className="solution-img">
                          <a href="#">
                            <img src="img/Consulting.jpg" alt="" />
                          </a>
                        </div>
                        <div className="solution-content">
                          <div className="solution-title">
                            <h4 className="sub-title">Consulting</h4>
                            <h3 className="title">
                              <a href="#">
                                Empowering Businesses with World-className
                                Services
                              </a>
                            </h3>
                          </div>
                          <a className="popup-video" href="#">
                            <img src="img/icons/Data-Migration-icon.svg" />
                          </a>
                        </div>
                      </div>
                      {/* <!-- Solution Item End --> */}
                    </div>
                    <div className="col-lg-12">
                      {/* <!-- Solution Item Start --> */}
                      <div
                        className="solution-item solution-item-sm"
                        data-aos="flip-left"
                      >
                        <div className="solution-img">
                          <a href="#">
                            <img src="img/EnterpriseIntegration.jpg" alt="" />
                          </a>
                        </div>
                        <div className="solution-content">
                          <div className="solution-title">
                            <h4 className="sub-title">
                              Enterprise Integration
                            </h4>
                            <h3 className="title">
                              <a href="#">
                                Expertise and Innovation, Driving Your Success
                              </a>
                            </h3>
                          </div>
                          <a className="popup-video" href="#">
                            <img src="img/icons/customer-service-icon.svg" />
                          </a>
                        </div>
                      </div>
                      {/* <!-- Solution Item End --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <NavLink
                className="btn rounded-0 btn-lg mx-auto text-secondary py-3 px-4 mt-4"
                to="/serviceNow"
              >
                View All
                <i className="ms-2" data-lucide="move-right"></i>
              </NavLink>
            </div>
            {/* <!-- Solution Content Wrap End --> */}
          </div>
          {/* <!-- Solution Wrap End --> */}
        </div>
      </div>
      {/* Industries */}
      <HomeIndustriesSection />

      {/* Video Section */}
      <div
        className="section seven-cta-section-04 section-padding"
        style={{ backgroundImage: "url(img/bg/cta-bg3.jpg)" }}
      >
        <div className="container">
          <div className="cta-wrap">
            <div className="row align-items-center">
              <div className="col-lg-5">
                {/* <!-- Cta Left Start --> */}
                <div className="cta-left" data-aos="fade-right">
                  <div className="section-title">
                    <h2 className="title white">
                      To make requests for further information, contact us
                    </h2>
                  </div>
                  <div className="cta-info">
                    <div className="cta-text">
                      <NavLink
                        className="btn rounded-0 btn-lg mx-auto text-secondary px-4 mt-4"
                        to="/contactUs"
                      >
                        Contact Us
                        <MoveRight className="ms-2" />
                      </NavLink>
                    </div>
                  </div>
                </div>
                {/* <!-- Cta Left End --> */}
              </div>
              <div className="col-lg-7">
                {/* <!-- Cta Right Start --> */}
                <div className="cta-right" data-aos="flip-left">
                  <div
                    className="video-wrap"
                    style={{ backgroundImage: "url(img/bg/VideoThumb.jpg)" }}
                  >
                    <div className="shape-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="54.551"
                        height="53.547"
                        viewBox="0 0 54.551 53.547"
                      >
                        <path
                          id="Path_283"
                          data-name="Path 283"
                          d="M.122,13.313l38.2,40.933L54.673.7.122,13.313"
                          transform="translate(-0.122 -0.699)"
                          fill="#51bb7e"
                        />
                      </svg>
                    </div>
                    <div className="shape-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="282.739"
                        height="286.459"
                        viewBox="0 0 282.739 286.459"
                      >
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x2="0"
                            y2="1"
                            gradientUnits="objectBoundingBox"
                          >
                            <stop offset="0" stopColor="#b5d336" />
                            <stop offset="1" stopColor="#51bb7e" />
                          </linearGradient>
                        </defs>
                        <g
                          id="Group_1"
                          data-name="Group 1"
                          transform="translate(-0.35 -0.334)"
                        >
                          <path
                            id="Path_2"
                            data-name="Path 2"
                            d="M230.475.334l52.614,40.6L52.806,286.793.35,246.2Z"
                            transform="translate(0)"
                            fill="url(#linear-gradient)"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="shape-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="120.749"
                        height="128.502"
                        viewBox="0 0 120.749 128.502"
                      >
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x1="0.5"
                            x2="0.5"
                            y2="1"
                            gradientUnits="objectBoundingBox"
                          >
                            <stop offset="0" stopColor="#fff" />
                            <stop
                              offset="1"
                              stopColor="#545454"
                              stopOpacity="0"
                            />
                          </linearGradient>
                        </defs>
                        <path
                          id="Path_284"
                          data-name="Path 284"
                          d="M120.1.974l1.552,1.154L2.456,129.476.9,128.322Z"
                          transform="translate(-0.904 -0.974)"
                          fill="url(#linear-gradient)"
                        />
                      </svg>
                    </div>
                    {/* <!-- Video Content Start --> */}
                    <div className="video-content text-center">
                      <div className="video-section-title">
                        <h2 className="title white">
                          We focus on your <br />
                          tech success
                        </h2>
                      </div>
                      <div className="play-btn-02">
                        <a
                          className="popup-video"
                          target="_blank"
                          href="https://www.youtube.com/watch?v=HvjQaPSgHCE"
                        >
                          <Play size="32" fill="white" />
                        </a>
                      </div>
                    </div>
                    {/* <!-- Video Content End --> */}
                  </div>
                </div>
                {/* <!-- Cta Right End --> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* News and Updates */}
      <HomeNewsUpdatesSection />
    </>
  );
}

export default Home;
